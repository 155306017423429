import { LoadingOutlined } from "@ant-design/icons";
import { Card, Col, Collapse, Layout, Row, Spin } from "antd";
import React, { useEffect, useMemo, useRef, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { WEB_API_ENDPOINT } from "../../utils/constants";
import DataCommonsClient from "../../utils/DataCommonsClient";
import AppLayoutContent from "../shared/AppLayoutContent";
import { ChartFootnote } from "../shared/components";

const ChartHeader = styled.div`
  padding-bottom: 2rem;
  h2 {
    font-size: 36px;
    margin-bottom: 10px;
  }
  p {
    padding: 0;
  }
`;

const DatacommonsMapContainer = styled.div`
  overflow: hidden;
  datacommons-slider::part(container) {
    margin-bottom: 0;
    border: 0;
    border-top: 1px solid #e3e3e3;
    border-radius: 0;
    .chart-content {
      padding: 16px !important;
    }
  }
`;

const ChartContainer = styled.div`
  datacommons-map::part(source-separator) {
    display: none;
  }
  datacommons-map::part(source-show-metadata-link) {
    display: none;
  }

  datacommons-line::part(source-separator) {
    display: none;
  }
  datacommons-line::part(source-show-metadata-link) {
    display: none;
  }

  datacommons-bar::part(source-separator) {
    display: none;
  }
  datacommons-bar::part(source-show-metadata-link) {
    display: none;
  }

  datacommons-highlight::part(source-separator) {
    display: none;
  }
  datacommons-highlight::part(source-show-metadata-link) {
    display: none;
  }
  datacommons-highlight::part(container) {
    margin: -24px !important;
    width: calc(100% + 48px);
    display: inline-block;
  }
`;

const { Panel } = Collapse;

const Goals: React.FC<{
  placeDcid: string;
  variableDcid: string;
  //setVariableDcid: (variableDcid: string) => void;
}> = (props) => {
  const { placeDcid } = props;
  const location = useLocation();
  const history = useHistory();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const [fullData, setFullData] = useState([]);
  const [payload, setPayload] = useState([]);
  const [info, setInfo] = useState([]);
  const [indicator, setIndicator] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [entities, setEntities] = useState(["Earth"]);
  const [indicators, setIndicators] = useState(null);
  const [topics, setTopics] = useState([]);

  const svgRef = useRef();

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/config/sidebar_merged.json`)
      .then((response) => response.json())
      .then((data) => {
        setTopics(data.sort((a, b) => a.label.localeCompare(b.label)));
      })
      .catch((error) => console.error("Error loading sidebar_merged.json:", error));
  }, []);

  async function getInfo<T>(code: string): Promise<T> {
    const response = await fetch(`${WEB_API_ENDPOINT}/api/variable/info?dcids=${code}`);
    return await (response.json() as Promise<T>);
  }

  const fullfil = async (code: string, places: string[]) => {
    const client = new DataCommonsClient({ apiRoot: WEB_API_ENDPOINT });
    const newPayload = {
      dc: "undata",
      entities: entities,
      variables: [inputValue],
      childEntityType: "",
      comparisonEntities: [],
      comparisonVariables: [],
    };
    setPayload(newPayload);
    return new Promise((resolve, reject) => {
      client
        .fulfill({
          dc: "undata",
          entities: places,
          variables: [code],
          childEntityType: "",
          comparisonEntities: [],
          comparisonVariables: [],
        })
        .then((v) => resolve(v))
        .catch((err) => reject(err));
    });
  };
  const getFullfillResponse = (response, code) => {
    let results = [];
    if (typeof response.config.categories != "undefined") {
      if (typeof response.config.categories[0].blocks != "undefined") {
        results = prioritizeCharts(response.config.categories[0].blocks, response);
      }
    }
    return results;
  };

  const prioritizeCharts = (blocks, response) => {
    const charts = [];
    const highlights = [];
    blocks.forEach((element) => {
      if (typeof element.columns[0] != "undefined") {
        if (element.columns[0].tiles[0].type != "PLACE_OVERVIEW" && element.columns[0].tiles[0].type != "HIGHLIGHT") {
          element.columns[0].tiles[0].statVarKey = findStatVarKeys(element.columns[0].tiles[0].statVarKey, response.config.categories[0].statVarSpec);
          charts.push(element);
        }
      }
    });

    blocks.forEach((element) => {
      if (typeof element.columns[0] != "undefined") {
        if (element.columns[0].tiles[0].type == "HIGHLIGHT") {
          element.columns[0].tiles[0].statVarKey = findStatVarKeys(element.columns[0].tiles[0].statVarKey, response.config.categories[0].statVarSpec);
          highlights.push(element);
        }
      }
    });
    return [...charts, ...highlights];
  };

  const findStatVarKeys = (statVarKeys, statVarSpec) => {
    const results = [];
    statVarKeys.forEach((key) => {
      if (statVarSpec.hasOwnProperty(key)) {
        results.push(statVarSpec[key].statVar);
      }
    });
    return results;
  };

  const findIndicatorByCode = (code, data) => {
    for (let i = 0; i < data.length; i++) {
      const indicator = data[i];
      if (indicator.indicator_codes && indicator.indicator_codes.includes(code)) {
        return indicator;
      }
      if (indicator.children && indicator.children.length > 0) {
        const childIndicator = findIndicatorByCode(code, indicator.children);
        if (childIndicator) {
          return childIndicator;
        }
      }
    }
    return null; // Indicator not found
  };

  const renderChartWithWidget = (chartType, chartData, chartTile, widgetType, widgetData, widgetTile) => {
    const footnote = "This chart incorporates various dates to create the most relevant visual. " + (chartData.footnote ? chartData.footnote : "");

    // Chart rendering
    const renderChart = (() => {
      switch (chartType) {
        case "MAP":
          const channel = `map-${widgetTile.statVarKey[0]}`;
          return (
            <datacommons-map
              apiRoot={WEB_API_ENDPOINT}
              header={chartTile.title}
              subscribe={channel}
              variable={chartTile.statVarKey[0]}
              parentPlace={entities.join(" ")}
              childPlaceType="Country"
              showexploremore="false"
              date="HIGHEST_COVERAGE"
            >
              <div style={{ borderRadius: 0 }} slot="footer">
                <datacommons-slider
                  apiRoot={WEB_API_ENDPOINT}
                  publish={channel}
                  variable={widgetTile.statVarKey[0]}
                  parentPlace={entities.join(" ")}
                  childPlaceType="Country"
                />
                <ChartFootnote text={footnote} />
              </div>
            </datacommons-map>
          );
        case "BAR":
          return (
            <datacommons-bar
              apiRoot={WEB_API_ENDPOINT}
              header={chartTile.title}
              variables={chartTile.statVarKey.join(" ")}
              places={entities.join(" ")}
              showexploremore="false"
            />
          );
        case "LINE":
          return (
            <datacommons-line apiRoot={WEB_API_ENDPOINT} header={chartTile.title} variables={chartTile.statVarKey.join(" ")} places={entities.join(" ")} />
          );
        case "HIGHLIGHT":
          return <datacommons-highlight apiRoot={WEB_API_ENDPOINT} header={chartTile.title} variable={chartTile.statVarKey[0]} place={entities.join(" ")} />;
        default:
          return null;
      }
    })();

    // Widget rendering
    const renderWidget = (() => {
      switch (widgetType) {
        case "MAP":
          const channel = `map-${widgetTile.statVarKey[0]}`;
          return (
            <datacommons-map
              apiRoot={WEB_API_ENDPOINT}
              header={widgetTile.title}
              variable={widgetTile.statVarKey[0]}
              subscribe={channel}
              parentPlace={entities.join(" ")}
              childPlaceType="Country"
              showexploremore="false"
              date="HIGHEST_COVERAGE"
            >
              <div style={{ borderRadius: 0 }} slot="footer">
                <datacommons-slider
                  apiRoot={WEB_API_ENDPOINT}
                  publish={channel}
                  variable={widgetTile.statVarKey[0]}
                  parentPlace={entities.join(" ")}
                  childPlaceType="Country"
                />
                <ChartFootnote text={footnote} />
              </div>
            </datacommons-map>
          );
        case "BAR":
          return (
            <datacommons-bar
              apiRoot={WEB_API_ENDPOINT}
              header={widgetTile.title}
              variables={widgetTile.statVarKey.join(" ")}
              places={entities.join(" ")}
              showexploremore="false"
            />
          );
        case "LINE":
          return (
            <datacommons-line apiRoot={WEB_API_ENDPOINT} header={widgetTile.title} variables={widgetTile.statVarKey.join(" ")} places={entities.join(" ")} />
          );
        case "HIGHLIGHT":
          return <datacommons-highlight apiRoot={WEB_API_ENDPOINT} header={widgetTile.title} variable={widgetTile.statVarKey[0]} place={entities.join(" ")} />;
        default:
          return null;
      }
    })();

    // Generate HTML string for the widget
    const widgetHtml = ReactDOMServer.renderToStaticMarkup(renderWidget);

    // Return chart and widget side by side with HTML below
    return (
      <div style={{ display: "flex", flexDirection: "column", gap: "1rem", alignItems: "flex-start" }}>
        <div style={{ display: "flex", gap: "1rem", alignItems: "flex-start" }}>
          <div style={{ flex: "2" }}>{renderChart}</div>
          <div style={{ flex: "1" }}>
            <div style={{ backgroundColor: "#f9f9f9", padding: "1rem", borderRadius: "8px", marginTop: "1rem" }}>
              <h4>Widget HTML:</h4>
              <pre style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}>{widgetHtml}</pre>
            </div>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const fetchInfo = async () => {
      try {
        const info = await getInfo(indicator);
        setInfo(info);
      } catch (error) {
        console.error("Error fetching indicator info:", error);
      }
    };

    const fetchFullfil = async () => {
      try {
        const data = await fullfil(indicator, entities);
        setFullData(data);
        setIndicators(getFullfillResponse(data, indicator));
      } catch (error) {
        console.error("Error fetching full data:", error);
      }
    };

    fetchInfo();
    fetchFullfil();
  }, [indicator, entities]);

  const submit = () => {
    setIndicator(inputValue);
    const updatedEntities = typeof entities === "string" ? entities.split(",").map((e) => e.trim()) : entities;
    setEntities(updatedEntities);
  };

  const Spinner = () => {
    return (
      <Spin
        indicator={
          <LoadingOutlined
            style={{
              paddingLeft: "1.5rem",
              fontSize: "1.5rem",
            }}
            spin
          />
        }
      />
    );
  };

  return (
    <>
      <AppLayoutContent className="container">
        <Layout style={{ padding: "2rem" }}>
          <ChartHeader>
            <h2>Indicator Metadata</h2>
            <p>
              <input onChange={(e) => setInputValue(e.target.value)} type="text" placeholder="Indicator code" />
              <input onChange={(e) => setEntities(e.target.value)} type="text" value={entities.join(", ")} />
              <button onClick={submit}>Get MetaData</button>
            </p>
            <small>Example: who/FINPROTECTION_IMP_NP190_POP</small>
          </ChartHeader>

          <Card style={{ marginBottom: "1rem" }}>
            <p>
              Info:{" "}
              <a href={`${WEB_API_ENDPOINT}/api/variable/info?dcids=${indicator}`} target="_blank">
                /api/variable/info?dcids={indicator}
              </a>
            </p>
            <div>
              <Collapse>
                <Panel header="View Data" key="1">
                  <pre>{JSON.stringify(info, null, 2)}</pre>
                </Panel>
              </Collapse>
            </div>
          </Card>

          <Card style={{ marginBottom: "1rem" }}>
            <p>Fullfil:</p>
            <div>
              <Collapse>
                <Panel header="Payload" key="1">
                  <pre>{JSON.stringify(payload, null, 2)}</pre>
                </Panel>
                <Panel header="View Data" key="2">
                  <pre>{JSON.stringify(fullData, null, 2)}</pre>
                </Panel>
              </Collapse>
            </div>
          </Card>

          <Card style={{ marginBottom: "1rem" }}>
            <p>Widgets:</p>
            {!indicators ? (
              <Spinner />
            ) : (
              indicators.map((item, index) => (
                <Row key={index} style={{ padding: "1rem" }} gutter={32}>
                  <Col className="-dc-indicator-chart" span={24}>
                    {item.columns.map((col) =>
                      col.tiles.map((tile, tileIndex) => {
                        const chartType = tile.type;
                        const chartData = item;
                        const chartTile = tile;

                        const widgetType = tile.type;
                        const widgetData = item;
                        const widgetTile = tile;

                        return (
                          <div key={`chart-widget-${tileIndex}`} style={{ backgroundColor: "#fff", borderRadius: "8px", padding: "1rem" }}>
                            {renderChartWithWidget(chartType, chartData, chartTile, widgetType, widgetData, widgetTile)}
                          </div>
                        );
                      })
                    )}
                  </Col>
                </Row>
              ))
            )}
          </Card>
        </Layout>
      </AppLayoutContent>
    </>
  );
};

export default Goals;
